import {ASSETS} from "../common/images";
import {Link} from "gatsby";
import * as React from "react";

const BlogList = () => {

    const blogListData = [
        {
            blogImg: ASSETS.BLOG.BlogWebDevelopment,
            date: '21 Oct, 2021',
            title: ' Ace your Web Application Development! - A Top-Notch Guide For Super Success',
            description: 'Web applications have seen massive adoption across several business domains. From interactive features to static web pages, web applications have come a long way, resembling a mobile application.....',
            url: 'ace-your-web-application-development',
        },
        {
            blogImg: ASSETS.BLOG.BlogEcommerce1,
            date: '8 Oct, 2021',
            title: ' Building an App like Amazon - Step-By-Step Process',
            description: 'Marketplace apps like Amazon have taken the shopping experiences to the next level. So if you are also preparing and planning to venture into this ever-evolving e-commerce marketplace, it is a great idea as the online trend seems to grow....',
            url: 'a-complete-guide-to-create-an-ecommerce-app-like-amazon',
        },
        {
            blogImg: ASSETS.BLOG.BlogDemand,
            date: '22 Sep, 2021',
            title: ' Mobile Apps Ideas to Fuel Different Business Industries',
            description: 'The world is changing at a rapid speed where technology has simplified everything. So why not take advantage of flourishing on-demand apps that are gaining popularity around the globe. Consumers these days are searching for speed, convenience...',
            url: 'mobile-app-ideas-to-fuel-different-business-industries',
        },
        {
            blogImg: ASSETS.BLOG.InternetOfThings,
            date: '19 Jan, 2019',
            title: 'IoT Complete Guide: Evolution, Application & Benefits',
            description: 'Let’s forget about the technical part, for a moment. And, focus on the words that “Internet of Things” encapsulates. IoT contains two wide words that are ‘Internet’ and ‘Things’. Internet, as we know, is a “Global system of interconnected...',
            url: 'the-evolution-of-iot-application-and-benefits',
        },
        {
            blogImg: ASSETS.BLOG.RightCompanyToJoin,
            date: '12 Jan, 2019',
            title: 'How to Select the Right Company to Join, for Your Career Growth',
            description: 'Your workplace is your second home! You spend almost half of your day here. And you are unquestionably making the biggest mistake of your life, if you don’t know exactly the criteria you must consider to select the right...',
            url: 'how-to-select-the-right-company-to-join',
        },
        {
            blogImg: ASSETS.BLOG.StatisticsAndAnalytics,
            date: '5 Jan, 2019',
            title: 'Top 10 Technology Trends in 2019',
            description: 'Technology is skyrocketing with many new developments every day. We have already entered into an era where technical dependency is rampant. Undoubtedly, 2019 is going to make many new additions to the ever-evolving technology trends. And, the same is...',
            url: 'top-10-technology-trends-2019',
        },
    ]
    return (
        <>
        <div className="left-side">
            {blogListData.map((row, index) => (
                <div className="row" key={index}>
                    <div className="col-md-12">
                        <Link to={row.url} className="blog-wrap">
                            <div className="row">
                                <div className="col-xl-5 col-lg-6 pe-md-0">
                                    <div className="blog-wrap-col-first">
                                        <img src={row.blogImg} className="blog-img" alt="BlogsF|GeekyBones" />
                                    </div>
                                </div>
                                <div className="col-xl-7 col-lg-6">
                                    <div className="blog-wrap-col-second">
                                        <button className="common-btn-blog">{row.date}</button>
                                        <h4>{row.title}</h4>
                                        <p>{row.description}</p>
                                        <div className="read-more-wrap">
                                            <div className="read-more">Read More <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            ))}
        </div>
        {/*<div className="pagination-wrap">*/}
        {/*    <nav aria-label="Page navigation example">*/}
        {/*        <ul className="pagination">*/}
        {/*            <li className="page-item"><a className="common-arrows left-arrow" href="javascript:void(0)"><img src={ASSETS.BLOG.PaginationArrowLeft} alt="" className="common-arrow"/></a></li>*/}
        {/*            <li className="page-item"><a className="page-link active" href="javascript:void(0)">1</a></li>*/}
        {/*            <li className="page-item"><a className="page-link" href="javascript:void(0)">2</a></li>*/}
        {/*            <li className="page-item"><a className="page-link" href="javascript:void(0)">3</a></li>*/}
        {/*            <li className="page-item"><a className="common-arrows right-arrow" href="javascript:void(0)"><img src={ASSETS.BLOG.PaginationArrowRight} alt="" className="common-arrow"/></a></li>*/}
        {/*        </ul>*/}
        {/*    </nav>*/}
        {/*</div>*/}
        </>
    )
}
export default BlogList;
